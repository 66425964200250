import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import withContext from '../ContextAPI/Context_HOC';
import GoogleAnalytics from 'react-ga';
import ThermometerChooser from '../Fund/ThermometerChooser';
import ErrorBoundary from '../Error/ErrorBoundary';
import HomeCarousel from './HomeCarousel';
import HomeBlockList from './HomeBlockList';
import PreviousBlockList from './PreviousBlockList';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { ContextProviderInterface } from '../ContextAPI/ContextProviderInterface';
import AuthService from '../Login/AuthService';
import { SpecialDayDisplay } from '@usbnc/data-api-proxies';
import { SortOrder } from '@kentico/kontent-delivery';
import { Subscription } from 'rxjs';

interface HomeProps {
  context: ContextProviderInterface;
  location: Location;
}

interface HomeState {
  upcomingDates: SpecialDayDisplay[];
  linkdisplay: JSX.Element[];
  allMessages: [];
  feastmessages: [];
  feasturlname: [];
  isOpen: boolean;
  isExpanded: boolean;
  isExpandedPreviousBlocks: boolean;
  token: string | null;
  decodedtoken: string;
  PersonId: string;
  homeBlockRows: [];
}

class Home extends Component<HomeProps, HomeState> {
  profilefn: ProfileFunction;
  auth: AuthService;
  subscription: any;
  getUpcomingDatesSubscription: Subscription;

  constructor(props: HomeProps) {
    super(props);
    this.profilefn = new ProfileFunction();
    this.auth = props.context.authService;
    this.state = {
      upcomingDates: [],
      linkdisplay: [],
      allMessages: [],
      feastmessages: [],
      feasturlname: [],
      isOpen: false,
      isExpanded: false,
      isExpandedPreviousBlocks: false,
      token: this.auth.getToken(),
      decodedtoken: this.auth.getDecodedToken(),
      PersonId: this.profilefn.getprofileinfo(this.auth.getDecodedToken()).bid,
      homeBlockRows: [],
    };

    const page = this.props.location.pathname + this.props.location.search;
    const userId = this.state.PersonId;

    const options = { gaOptions: { userId } };
    GoogleAnalytics.initialize(process.env.REACT_APP_GA_UA ?? '', options);
    GoogleAnalytics.pageview(page);
  }
  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleShowPrevious = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  handleShowPreviousBlocks = () => {
    this.setState({
      isExpandedPreviousBlocks: !this.state.isExpandedPreviousBlocks,
    });
  };

  addedTime = (upcomingDate: SpecialDayDisplay) => {
    switch (upcomingDate.nameExtendedAsciiAndStylizedQuotes) {
      case 'First Day of Ridván':
        return ' (3:00 p.m. standard time) * ';
      case 'Declaration of the Báb':
        return ' (2 hours after sunset on May 22)';
      case "Ascension of Bahá'u'lláh":
        return ' (3:00 a.m. standard time) * ';
      case 'Martyrdom of the Báb':
        return ' (12:00 noon, standard time) * ';
      case 'Ascension of ‘Abdu’l-Bahá':
        return ' (1:00 a.m. standard time)';
      default:
        return '';
    }
  };

  componentDidMount() {
    this.props.context.hideBreadcrumbs();
    let datestring = '';
    let taxonomyterm = '';
    let listenhtml: string | JSX.Element = '';
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('cover_letter')
      .orderParameter('elements.base__datepublished', SortOrder.desc)
      .limitParameter(5)
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let links: JSX.Element[] = [];

          response.items.forEach((x) => {
            datestring = x.base__datepublished.rawData.value.substring(0, 4);
            taxonomyterm = x.cover_letter.value[0].codename;
            listenhtml = x.linked_items.value[0]?.base__listenhtml?.value ? (
              <div
                data-cy="listen-section-container"
                dangerouslySetInnerHTML={{
                  __html: x.linked_items.value[0].base__listenhtml.value,
                }}
              />
            ) : (
              ''
            );
            links.push(
              <p key={x.base__title.value}>
                <span className="small-text">
                  {new Date(x.base__datepublished.value).toLocaleDateString('en-US', this.props.context.dateOptions)}
                </span>
                <br />
                <Link
                  to={{
                    pathname: `/guidance/${taxonomyterm}/${x.url_slug.value}-${datestring}`,
                  }}
                >
                  {x.base__title.value}
                </Link>{' '}
                {listenhtml}{' '}
              </p>
            );
          });
          this.setState({ linkdisplay: links });
        }
      });
    this.getUpcomingDatesSubscription = this.props.context.calendarService.getUpcomingDates(
      33,
      (upcomingDates: SpecialDayDisplay[]) => {
        this.setState({ upcomingDates: upcomingDates });
      }
    );
  }
  componentWillUnmount() {
    this.props.context.showBreadcrumbs();
    this.subscription?.unsubscribe();
    this.getUpcomingDatesSubscription.unsubscribe();
  }
  componentDidUpdate() {
    if (!this.state.decodedtoken) {
      var receivedDecodedToken = this.auth.getDecodedToken();
      if (receivedDecodedToken) {
        this.setState({ decodedtoken: receivedDecodedToken });
      }
    }
  }
  render() {
    const isdelegate = this.profilefn.isPersonDelegate(this.state.decodedtoken);
    const isadmin = this.profilefn.isAdmin(this.state.decodedtoken);
    return (
      <div>
        <HomeCarousel />
        <Row>
          <Col sm={12} md={8} className="homepage-right-padding">
            <div className="home-box">
              <h4>Upcoming Holy Days and Feasts</h4>
              <ul className="plain-list">
                {this.state.upcomingDates.map((upcomingDate, key) => (
                  <li key={key}>
                    <strong>{upcomingDate.nameExtendedAsciiAndStylizedQuotes}:</strong> {upcomingDate.dateDisplay},{' '}
                    {upcomingDate.date?.substring(0, 4)} {this.addedTime(upcomingDate)}
                  </li>
                ))}
                <li>
                  <a href="https://bahai-web.s3.amazonaws.com/resources/BE-181-Feasts-and-Holy-Days.pdf">
                    Holy Days and Feasts B.E. 181
                  </a>
                </li>
                <li>
                  <a href="https://bahai-messages.s3.amazonaws.com/uhj/2014/1211_UHJ_50-Yr_Table_of_Dates/20141211_UHJ_50-Yr_Table_of_Dates_(English).pdf">
                    Bahá’í Calendar for Next 50 Years
                  </a>
                </li>
                <li>
                  <a href="https://bahai-web.s3.amazonaws.com/resources/Implementation+of+the+Badi+Calendar+-+UHJ+Letter+of+July+10%2C+2014.pdf">
                    Implementation of the Badi Calendar
                  </a>
                </li>
              </ul>
              {this.state.upcomingDates.some((d) => this.addedTime(d).includes(' * ')) && (
                <div className="small-bottom-margin" data-cy="day-saving-note">
                  * When daylight saving time is in effect in a state, the times for Holy Day commemorations are held one hour
                  later than the time specified.
                </div>
              )}
              <div className="center">
                <Link to="/guidance/feast_message" className="red-button">
                  Download the latest
                  <br />
                  Feast Message
                </Link>
              </div>
            </div>
            <HomeBlockList />
            <div className="center medium-top-margin small-bottom-margin" onClick={this.handleShowPreviousBlocks}>
              {this.state.isExpandedPreviousBlocks ? (
                <h6 className="link-text">Hide Previous Announcements</h6>
              ) : (
                <h6 className="link-text">View Previous Announcements</h6>
              )}
            </div>
            {this.state.isExpandedPreviousBlocks && (
              <span>
                <PreviousBlockList />
              </span>
            )}
          </Col>
          <Col sm={12} md={4}>
            <OptimizelyFeature feature="delegate_page">
              {(isEnabled) =>
                (isEnabled && isdelegate) || isadmin ? (
                  <div className="home-box">
                    <h4>Welcome Delegate!</h4>
                    <p>
                      Access the delegates’ webpage <Link to="/elections/delegates">here</Link>
                    </p>
                  </div>
                ) : null
              }
            </OptimizelyFeature>
            <div className="home-box">
              <h4>The National Spiritual Assembly’s 2024 delegate election call</h4>
              <p>
                <Link to="/elections/unit-convention">Access election call and its enclosures</Link>
              </p>
            </div>
            <div className="center grey-box teal-background">
              <h4 className="white">Online Balloting System (OBS)</h4>
              <Row>
                <Col xs={6}>
                  <OptimizelyFeature feature="obs_single_sign_on">
                    {(isEnabled) =>
                      isEnabled ? (
                        <Link to="/SingleSignOnRedirect/OBS" className="home-button">
                          Access OBS
                        </Link>
                      ) : (
                        <a href="https://obsbahai.org/" target="_blank" rel="noopener noreferrer" className="home-button">
                          Access OBS
                        </a>
                      )
                    }
                  </OptimizelyFeature>
                </Col>
                <Col xs={6} className="inline-vertical-center">
                  <p>
                    <a href="https://helpcenter.obsbahai.org/" target="_blank" rel="noopener noreferrer" className="white">
                      OBS Help Center
                    </a>
                  </p>
                  <p>
                    <a href="https://training.obsbahai.org/sign-in" target="_blank" rel="noopener noreferrer" className="white">
                      OBS Training
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="sidebar-box">
              <ErrorBoundary>
                <ThermometerChooser />
              </ErrorBoundary>
              <h4>Online Contribution System (OCS)</h4>
              <a
                className="home-button small-bottom-margin full-width center"
                href="https://ocs.bahai.us/ocs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contribute
              </a>
              <br />
              <a
                className="home-button small-bottom-margin full-width center"
                href="https://www.BahaiTreasurer.us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Office of the Treasurer
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(withContext(Home));
