import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';

function UnitCon() {
  return (
    <div>
      <h1>Delegate Elections/Unit Conventions</h1>
      <hr />
      {/* <p>
        The 2023 Unit Conventions will take place on the weekend of September 30–October 1. This page will be updated with
        Convention materials as they become available. If you have any questions, you may contact the Unit Convention Desk by
        email (<a href="mailto:unitconvention@usbnc.org">unitconvention@usbnc.org</a>) or phone (847.733.3488)
      </p> */}
      <p>The 117th Bahá’í National Convention will be held April 24-27, 2025</p>
      <br />
      <Accordion defaultExpanded>
        <AccordionSummary
          aria-controls="Planning for Elections"
          id="Planning for the Delegate Elecitons"
          expandIcon={<ExpandMore />}
        >
          <h4 className="u-position-relative">Planning for the Delegate Elections</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+An+Overview+of+Host+Assembly+Duties.pdf">
                2024 An Overview of Host Assembly Duties (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+Tasks+for+Unit+Convention.pdf">
                2024 Tasks for Unit Convention (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+Unit+Convention+Planning+Timeline.pdf">
                2024 Unit Convention Planning Timeline (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+Facilitating+a+Delegate+Election+FAQs+(updated+December+2023).pdf">
                2024 Facilitating a Delegate Election FAQs (updated December 2023) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Tellers'+Instructions+for+Unit+Convention+(Sep+2024).pdf">
                2024 Tellers’ Instructions for Unit Convention (Revised September 2024) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Accessibility-considerations-for-gatherings.pdf">
                Accessibility Considerations for Gatherings (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+Using+eMembership2+for+Unit+Convention.pdf">
                2024 Using eMembership2 for Unit Convention (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024+Planning+the+Baha'i+Unit+Convention.pptx">
                2024 Planning Unit Convention presentation (PPTX)
              </a>
            </li>
            <li>
              <strong>Video: </strong>
              <a target="_blank" rel="noopener noreferrer" href="https://player.vimeo.com/video/947584812">
                Unit Convention: Getting Started
              </a>
            </li>
            <li>
              <strong>Video: </strong>
              <a target="_blank" rel="noopener noreferrer" href="https://player.vimeo.com/video/991777519">
                Planning the Bahá’í Unit Convention
              </a>
            </li>
            <li>
              <strong>Video: </strong>OBS Webinar for Host Assembly Officers (
              <a target="_blank" rel="noopener noreferrer" href="https://player.vimeo.com/video/1007687529">
                Watch)
              </a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary area-controls="Educational-Resources" id="Educational Resources" expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">Delegate Election Educational Resources</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <strong>Video: </strong>Bahá’í Electoral Process (
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=-eOVRW22D6I">
                Watch
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.dropbox.com/s/bpwylkisl1fu218/Bah%C3%A1%E2%80%99%C3%AD%20Elections.mov?dl=0"
              >
                Download
              </a>
              )
            </li>
            <li>
              <strong>Video: </strong>A Model of Spiritually Based Elections (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.dropbox.com/s/g81q6a4i7j5g5vd/A%20Model%20of%20Spiritually%20Based%20Elections%202019.mov?dl=0"
              >
                Download
              </a>
              )
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Bahai-Unit-Conventions-A-Brief-History.pdf">
                Bahá’í Unit Conventions – A Brief History
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+8.2+section+12+and+8.3+section+25+extracts+re+serving+on+institutions.pdf">
                Ruhi Book 8.2 section 12 and 8.3 section 25
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+10.2+sections+8-9+extracts+on+consultation.pdf">
                Ruhi Book 10.2 sections 8-9
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Election+Graphic.jpeg">
                Election Graphic
              </a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary area-controls="Eleciton Call" id="Election Call" expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">Election Call and Enclosures</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/20240826+To+the+American+Baha'i+Community+-+Election+call.pdf">
                The National Spiritual Assembly’s 2024 delegate election call (English) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/20240826+To+the+American+Baha'i+Community+-+Election+call+(Persian).pdf">
                The National Spiritual Assembly’s 2024 delegate election call (Persian) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/20240826+To+the+American+Baha'i+Community+-+Election+call+(Spanish).pdf">
                The National Spiritual Assembly’s 2024 delegate election call (Spanish) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Four-page+guidance+and+voting+instructions+for+election+call+mailing.pdf">
                Guidance and Voting Instructions (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Duties-of-Delegates-to-the-Bahai-National-Convention.pdf">
                Duties of Delegates to the Bahá’í National Convention (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Ballot-for-units-electing-a-single-delegate.pdf">
                Ballot for units electing a single Delegate (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Ballot-for-units-electing-two-delegates.pdf">
                Ballot for units electing two Delegates (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/Importance_of_participation_graphic.jpg">
                Importance of Participation (JPG)
              </a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          area-controls="Mailings to Host Assemblies"
          id="Mailings to Host Assemblies"
          expandIcon={<ExpandMore />}
        >
          <h4 className="u-position-relative">Contents of September Mailing to Host Assemblies</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/20240908+UCD+cover+letter+for+September+packet.pdf">
                Unit Convention Desk cover letter (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/20241005+NSA+greetings+to+the+friends+gathered+at+the+2024+Unit+Conventions.pdf">
                Unit Convention welcome letter from the National Spiritual Assembly - English (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Voting+Instr.+for+and+Duties+of+Conv.+Officers.pdf">
                Voting instructions for the election of Convention officers (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Voting+Instructions+for+the+Election+of+Delegates.pdf">
                Voting instructions for the election of delegate(s) - English (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Consultation+Questions+-+NSA+Questions+and+Concerns+for+Delegate+Consideration.pdf">
                Questions for the Consideration of the Assembled Delegates: Organized by Nine Year Plan Themes (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Key+Points+for+Registrars+at+Unit+Convention.pdf">
                Key Points for Registrars (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Key+Points+for+Sub-Units.pdf">
                Key Points for Sub-units (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Vouching+form-Unit+Convention+2024.pdf">
                Bahá’í Membership Vouching Form (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Baha'i+Membership+Data+Report.pdf">
                Bahá’í Membership Data Report Form (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Tellers'+Instructions+for+Unit+Convention+(Sep+2024).pdf">
                2024 Tellers’ Instructions for Unit Convention (Revised September 2024) (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+1-Delegate+Ballot.pdf">
                1-delegate ballot (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+2-Delegate+Ballot.pdf">
                2-delegate ballot (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/Information-to-Share-with-Delegate(s)-Elect.pdf">
                Information sheet for newly elected delegate(s) (PDF)
              </a>
            </li>
            <li>
              Note: A message from the Office of the Treasurer will be sent to host Assemblies by email ahead of Convention.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary area-controls="Convention Forms" id="Convention Forms" expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">Unit Convention Forms</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>Electoral Unit Convention Arrangements are to be completed through eMembership2</p>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Host+Assembly+Reflection+Sheet.pdf">
                Host Assembly Reflection Sheet (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+Unit+Convention+Secretary%27s+Report+Form.pdf">
                Unit Convention Secretary’s Report (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2022/Vouching-form-Unit-Convention-2022.pdf">
                Vouching Form (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2022/Bahai-Membership-Data-Report.pdf">
                Bahá’í Membership Data Report (PDF)
              </a>
            </li>
          </ul>
          <p>
            If a certificate of insurance is required to secure a site, and the Host Assembly does not have a Bahá’í Center,
            please download and complete the{' '}
            <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/2024-Request-for-Certificate-of-Insurance-Form.pdf">
              Insurance Certificate Request form
            </a>
            . Follow the instructions on the form to submit it to the insurance company.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary area-controls="Electoral Unit Maps" id="Electoral Unit Maps" expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">Electoral Unit Maps by Region</h4>
        </AccordionSummary>
        <AccordionDetails>
          <h6>The links below will open a mapping application in a new tab or window.</h6>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=45a078001ecc47a7919d7b2a6f535079"
              >
                Appalachia Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=d77bbbaa1c264213b4342c5e3f141ec3"
              >
                Atlantic Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=574d0c058a5347cfac8272d3ad928d5f"
              >
                California Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=3c2c00c5d5dd45a7bcf7c28292bed4f5"
              >
                Florida Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=3e0d37f5ad3c4f5e8fd1423b52dbb3eb"
              >
                Four Corners Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=4d3c69bacfaa4da88e6b3b8cfba62b93"
              >
                Heartland Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=96c43e09adb14f419a5238108c18174c"
              >
                Midwest Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=27d0b52aecb545659c1f4d19a4133b54"
              >
                Northeast Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=f0cffee7f960400d9fa0f763835aabae"
              >
                Northern Plains Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=f5b3fe15725a43958dceb54e0c47f2df"
              >
                Northwest Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=66ffc2c07eb64fed82c40abe42eba2a6"
              >
                Prairie States Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=82d426a3bb334efc8ac041463db136b7"
              >
                South Central Region EU Mapping
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://portal.usbnc.org/portal/apps/webappviewer/index.html?id=742949865a5948f2bd1abb53a86f45e4"
              >
                Southeast Region EU Mapping
              </a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          area-controls="Guidance and Policy Documents"
          id="Guidance and Policy Documents"
          expandIcon={<ExpandMore />}
        >
          <h4 className="u-position-relative">Guidance and Policy Documents</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/unit-convention/2019-Nature-of-Bahai-Elections.pdf">
                Nature of Bahá’í Elections (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/unit-convention/2019-Guidance-on-Unit-Convention.pdf">
                Unit Convention—selected guidance (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/unit-convention/2019-Importance-of-Bahai-elections-2007-03-25.pdf">
                Universal House of Justice message March 25, 2007 — Importance of Bahá’í Elections (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/unit-convention/2019-Functions-of-Delegates-to-National-Convention.pdf">
                Universal House of Justice message May 16, 2013 — Functions of Delegates to National Conventions (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Elections/Delegate+Elections/20230213-All-LSAs-Updated-Policy-for-the-Protection-of-Minors.pdf">
                Letter from the National Assembly to all Local Assemblies forwarding revised documents on the protection of minors
                (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Elections/Delegate+Elections/Policy-for-the-Protection-of-Children-JrYouth-and-Youth-revised-January-2023.pdf">
                Attachment 1 - Policy for the Protection of Children, Junior Youth, and Youth – revised January 2023 (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Elections/Delegate+Elections/Guiding-Principles-and-Standards-Pertaining-to-the-Protection-of-Minors-revised-January-2023.pdf">
                Attachment 2 - Guiding Principles and Standards Pertaining to the Protection of Minors – revised January 2023
                (PDF)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/forms/Elections/Delegate+Elections/National+Spiritual+Assembly-compilation-Oct+2023.pdf">
                October 2023 compilation on The National Spiritual Assembly (PDF)
              </a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary area-controls="Bookstore" id="Bookstore" expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">Unit Convention Bookstore</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>This information will assist host Assemblies and planning teams to set up a bookstore at Unit Convention.</p>
          <ul>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+BDS+(Introduction).docx">
                Introduction (DOCX)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+BDS+(Placing+an+order).xlsx">
                Placing an Order (XLSX)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+BDS+(Return+Instructions).docx">
                Return Instructions (DOCX)
              </a>
            </li>
            <li>
              <strong>Download: </strong>
              <a href="https://bahai-web.s3.amazonaws.com/Unit+Convention/2024/2024+BDS+(Return+form).docx">Return Form (DOCX)</a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default UnitCon;
