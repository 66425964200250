import { FC, useEffect, useState, ReactNode } from 'react';
import * as React from 'react';
import { slice, take } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import searchResultsEndpoint from './Utilities/SearchResultsEndpoint';
import SearchLink from './SearchLink';
import type SearchResultModel from './Utilities/SearchResultModel';
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, InputBase } from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import ErrorBoundary from '../Error/ErrorBoundary';
import { CircularProgress } from '@material-ui/core';
import { stringToBoolean } from '../Utilities/UtilityFunctions';
import type { History, Location } from 'history';
import type SearchCriteriaModel from './Utilities/SearchCriteriaModel';
import AdvancedSearchOptions from './AdvancedSearchOptions';
import withContext from '../ContextAPI/Context_HOC';
import { dateStringToDisplayDate } from '../Utilities/UtilityFunctions';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { from, combineLatest } from 'rxjs';
import FeatureKey from '../FeatureFlag/FeatureKey';
import type FeatureFlagIsEnabled from '../FeatureFlag/FeatureFlagIsEnabled';
import SearchExclusion, { SearchExclusionDisplayName } from './Utilities/SearchExclusion';

const allSearchExclusions = Object.values(SearchExclusion);

export const initializeSearchCriteriaModel: () => SearchCriteriaModel = () => {
  return {
    searchString: '',
    rowsPerPage: 10,
    currentPage: 1,
    startDate: '',
    endDate: '',
    excludes: [],
  };
};

export type SearchResultAndCriteriaModel = {
  values: SearchResultModel[];
  count: number;
  searchCriteria: SearchCriteriaModel;
  searchShowsScore: boolean;
};

export function getSearchCriteriaFromQueryString(queryStringFromUrl: string) {
  const queryStringValues = QueryString.parse(queryStringFromUrl);
  const newSearchCriteria = initializeSearchCriteriaModel();
  newSearchCriteria.searchString = (queryStringValues.searchString as string) ?? '';
  if (queryStringValues.rowsPerPage) {
    newSearchCriteria.rowsPerPage = parseInt(queryStringValues.rowsPerPage as string, 10);
  }
  if (queryStringValues.currentPage) {
    newSearchCriteria.currentPage = parseInt(queryStringValues.currentPage as string, 10);
  }
  newSearchCriteria.startDate = (queryStringValues.startDate as string) ?? '';
  newSearchCriteria.endDate = (queryStringValues.endDate as string) ?? '';
  if (queryStringValues.showCategories) {
    const showCategoriesAsBool = stringToBoolean(queryStringValues.showCategories);
    newSearchCriteria.showCategories = showCategoriesAsBool ? true : undefined;
  }
  if (queryStringValues.excludes) {
    if (Array.isArray(queryStringValues.excludes)) {
      newSearchCriteria.excludes = allSearchExclusions.filter((x) => (queryStringValues.excludes as string[]).includes(x));
    } else {
      newSearchCriteria.excludes = allSearchExclusions.filter((x) => queryStringValues.excludes === x);
    }
  }
  if (queryStringValues.orderByMostRelevant) {
    const orderByMostRelevantAsBool = stringToBoolean(queryStringValues.orderByMostRelevant);
    newSearchCriteria.orderByMostRelevant = orderByMostRelevantAsBool ? true : undefined;
  }

  return newSearchCriteria;
}

export function redirectIfCriteriaDoesntMatchCurrentUrl(
  searchCriteria: SearchCriteriaModel,
  queryStringFromUrl: string,
  history: History
) {
  const searchCriteriaStringified = QueryString.stringify(searchCriteria, { skipEmptyString: true, skipNull: true });
  if (queryStringFromUrl !== `?${searchCriteriaStringified}`) {
    history.push({ search: searchCriteriaStringified });
  }
}

export function buildSearchDescription(resultsAndCriteria: SearchResultAndCriteriaModel) {
  let searchDescription = `"${resultsAndCriteria.count}" results found for "${resultsAndCriteria.searchCriteria.searchString}"`;

  if (resultsAndCriteria.searchCriteria.startDate.length > 1 && resultsAndCriteria.searchCriteria.endDate.length > 1) {
    const startDate = dateStringToDisplayDate(resultsAndCriteria.searchCriteria.startDate);
    const endDate = dateStringToDisplayDate(resultsAndCriteria.searchCriteria.endDate);
    searchDescription += ` published between ${startDate} and ${endDate}`;
  } else if (resultsAndCriteria.searchCriteria.startDate.length > 1) {
    const startDate = dateStringToDisplayDate(resultsAndCriteria.searchCriteria.startDate);
    searchDescription += ` published on or after ${startDate}`;
  } else if (resultsAndCriteria.searchCriteria.endDate.length > 1) {
    const endDate = dateStringToDisplayDate(resultsAndCriteria.searchCriteria.endDate);
    searchDescription += ` published on or before ${endDate}`;
  }

  if (resultsAndCriteria.searchCriteria.excludes.length > 0) {
    const itemsNOTExcluded = allSearchExclusions.filter((x) => !resultsAndCriteria.searchCriteria.excludes.includes(x));
    if (itemsNOTExcluded.length > 0) {
      const inclusionDisplayNames = itemsNOTExcluded.map((x) => SearchExclusionDisplayName[x]);
      const lastDisplayName = inclusionDisplayNames.pop() ?? '';
      if (inclusionDisplayNames.length > 0) {
        const startingCommaSeparatedDisplayNames = inclusionDisplayNames.join(', ');
        searchDescription += ` and categorized as ${startingCommaSeparatedDisplayNames} or ${lastDisplayName}`;
      } else {
        searchDescription += ` and categorized as ${lastDisplayName}`;
      }
    } else {
      searchDescription += ' with no categories selected';
    }
  } else {
    searchDescription += ' with all categories selected';
  }

  if (!resultsAndCriteria.searchCriteria.orderByMostRelevant) {
    searchDescription += ' then ordered by Last Updated date';
  }

  return searchDescription;
}

const SearchResults: FC<
  {
    history: History;
    location: Location;
    context: {
      featureFlagIsEnabledFunction: typeof FeatureFlagIsEnabled;
    };
  } & WithOptimizelyProps
> = ({ history, location: { search }, optimizely, context: { featureFlagIsEnabledFunction } }) => {
  const queryStringFromUrl = search;
  const searchCriteriaFromQueryString = getSearchCriteriaFromQueryString(queryStringFromUrl);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState(searchCriteriaFromQueryString);
  const [userInputValue, setUserInputValue] = useState(searchCriteriaFromQueryString.searchString);
  let isGlsa = false;

  //we got new search from global input
  if (!queryStringFromUrl.includes('&') && searchCriteria.searchString != searchCriteriaFromQueryString.searchString) {
    setSearchCriteria({ ...searchCriteria, searchString: searchCriteriaFromQueryString.searchString });
    setUserInputValue(searchCriteriaFromQueryString.searchString);
  }

  const [errorMessage, setErrorMessage] = useState<ReactNode[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResultAndCriteriaModel>({
    values: [],
    count: 0,
    searchCriteria: searchCriteria,
    searchShowsScore: false,
  });

  useEffect(() => {
    redirectIfCriteriaDoesntMatchCurrentUrl(searchCriteria, queryStringFromUrl, history);
  }, [searchCriteria, queryStringFromUrl, history]);

  useEffect(() => {
    if (searchCriteria.searchString) {
      // If search value is present then call API
      setShowCircularProgress(true);
      setErrorMessage([]);
      isGlsa =
        searchCriteria.excludes.length == allSearchExclusions.length - 1 &&
        !searchCriteria.excludes.includes(SearchExclusion.GLSA);

      const searchResultsEndpointObservable = isGlsa
        ? searchResultsEndpoint({ ...searchCriteria, currentPage: 1, rowsPerPage: 500 })
        : searchResultsEndpoint(searchCriteria);

      const featureFlagSearchShowsScorePromise = featureFlagIsEnabledFunction(FeatureKey.SearchShowsScore, optimizely);
      const featureFlagSearchShowsScoreObservable = from(featureFlagSearchShowsScorePromise);

      const combinedObservables = combineLatest([searchResultsEndpointObservable, featureFlagSearchShowsScoreObservable]);
      const subscription = combinedObservables.subscribe(
        ([endpointResult, searchShowsScore]) => {
          const numberOfResultsFound = endpointResult['@odata.count'];
          let tempSearchValues = endpointResult ? endpointResult.value : [];

          if (isGlsa) {
            tempSearchValues = tempSearchValues
              .map((x) => {
                let chapter = x.secondcategorytype?.split('/')[0].match(/(\d+)/)?.join('');
                return { ...x, chapterNumber: Number.parseInt(chapter ?? '0') };
              })
              .sort((a, b) => a.chapterNumber - b.chapterNumber);

            const skip = (searchCriteria.currentPage - 1) * searchCriteria.rowsPerPage;
            tempSearchValues = slice(tempSearchValues, skip);
            tempSearchValues = take(tempSearchValues, searchCriteria.rowsPerPage);
          }

          const maxNumberOfPages = Math.ceil(numberOfResultsFound / searchCriteria.rowsPerPage);
          if (maxNumberOfPages && searchCriteria.currentPage > maxNumberOfPages) {
            // If current page is out of range then specify new criteria with the highest available page
            setSearchCriteria({
              ...searchCriteria,
              currentPage: maxNumberOfPages,
            });
          } else {
            // Set search results
            setSearchResults({
              values: tempSearchValues,
              count: numberOfResultsFound,
              searchCriteria: searchCriteria,
              searchShowsScore: searchShowsScore,
            });
            setErrorMessage([]);
            setShowCircularProgress(false);
          }
        },
        (err) => {
          setSearchResults({
            values: [],
            count: 0,
            searchCriteria: { ...searchCriteria, searchString: '' },
            searchShowsScore: false,
          });
          setErrorMessage([<p key="errorMessageParagraph">{err.message}</p>]);
          setShowCircularProgress(false);
        }
      );
      return () => subscription.unsubscribe();
    } else {
      // If search value was blank then display empty results
      setSearchResults({
        values: [],
        count: 0,
        searchCriteria: { ...searchCriteria, searchString: '' },
        searchShowsScore: false,
      });
      setErrorMessage([]);
      setShowCircularProgress(false);
    }
  }, [searchCriteria]);

  const pageMultiplier = searchCriteria.currentPage - 1;
  const searchDescription = buildSearchDescription(searchResults);

  return (
    <div>
      <Row middle="xs" center="xs">
        <InputBase
          inputProps={{ 'data-cy': 'searchtextbox' }}
          placeholder="Search…"
          className="bounding-box wide-form-min search-bar"
          value={userInputValue || ''}
          onChange={(event) => setUserInputValue(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter'
              ? setSearchCriteria({
                  ...searchCriteria,
                  searchString: userInputValue ?? '',
                  currentPage: 1,
                })
              : null
          }
        />
        <button
          className="primary-button"
          data-cy="searchbutton"
          onClick={() =>
            setSearchCriteria({
              ...searchCriteria,
              searchString: userInputValue ?? '',
              currentPage: 1,
            })
          }
        >
          Search
        </button>
      </Row>
      <Link
        data-cy="what-is-included-in-search-results"
        className="small-left-margin small-right-margin"
        to="/faq/books_and_literature/what-is-included-in-the-search-results"
      >
        What is included in the search results?
      </Link>
      <div>
        <hr className="small-left-margin small-right-margin" />
        <Row>
          <Col sm={12} md={8}>
            <AdvancedSearchOptions mobileShow={true} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
            {showCircularProgress ? (
              <CircularProgress data-cy="search-results-circular-progress" className="loading-animation" size={40} />
            ) : (
              <>
                <h6 data-cy="resultmessage" className="faded-text">
                  {searchDescription}
                </h6>
                <div data-cy="invalidinputcharacter">{errorMessage}</div>
                <Table>
                  <TableBody data-cy="resultrows">
                    {searchResults.values.length
                      ? searchResults.values.map((value, key) => (
                          <TableRow data-cy="datarow" key={key}>
                            <TableCell component="th" scope="row" className="no-padding">
                              <ErrorBoundary>
                                <SearchLink details={value} searchShowsScore={searchResults.searchShowsScore} history={history} />
                              </ErrorBoundary>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                  <TableFooter>
                    {searchResults.values.length ? (
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20, 30]}
                          colSpan={3}
                          count={searchResults.count}
                          rowsPerPage={searchCriteria.rowsPerPage}
                          page={pageMultiplier}
                          SelectProps={{
                            inputProps: { 'aria-label': 'Results per page' },
                            autoWidth: true,
                          }}
                          ActionsComponent={TablePaginationActions}
                          onRowsPerPageChange={(event) =>
                            setSearchCriteria({
                              ...searchCriteria,
                              rowsPerPage: parseInt(event.target.value, 10),
                            })
                          }
                          onPageChange={(event, newPage) =>
                            setSearchCriteria({
                              ...searchCriteria,
                              currentPage: newPage + 1,
                            })
                          }
                        />
                      </TableRow>
                    ) : null}
                  </TableFooter>
                </Table>
              </>
            )}
          </Col>
          <Col sm={12} md={4}>
            <AdvancedSearchOptions mobileShow={false} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
            <div className="grey-box">
              <h4>You may improve your search result by using the following tips:</h4>
              <p>
                - Add a ~ symbol to the end of a word that may contain diacriticals (ex: Bahá’í) in your search statements (ex:
                Bahai~)
              </p>
              <p>- Wrap your search statement in &quot;&quot; if you want to search for an exact phrase</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withContext(withOptimizely(SearchResults));
